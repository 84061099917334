<template>
  <v-tabs v-bind="$attrs" v-model="tabModel">
    <v-tab
      v-for="module in appModules"
      :key="module.title"
      :disabled="!canNavigateToAppModule(module.name)"
      :to="buildAppModuleRoute(module.name)"
    >
      <v-icon left>{{ module.icon }}</v-icon>
      <div v-if="visibleOnMdAndUp">
        {{ module.title }}
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { appModuleNavigatable } from "@/mixins/shared/navigatable/appModuleNavigatable";

export default {
  inheritAttrs: false,
  name: "ApplicationTabs",
  mixins: [reactiveMixin, appModuleNavigatable],
  data() {
    return {
      tabModel: undefined
    };
  }
};
</script>
